import React, { createContext, useState, useLayoutEffect } from "react";

// Create the context
export const RtlContext = createContext();

// Create a provider component
export const RtlProvider = ({ children }) => {
  const [isRtl, setIsRtl] = useState(false);
  const toggleRtl = () => {
    setIsRtl((prev) => !prev);
  };
  // Update the body's direction attribute based on RTL state
  useLayoutEffect(() => {
    document.body.setAttribute("dir", isRtl ? "rtl" : "ltr");
  }, [isRtl]);

  return (
    <RtlContext.Provider value={{ isRtl, setIsRtl }}>
      {children}
    </RtlContext.Provider>
  );
};
