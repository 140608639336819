import {
  Box,
  IconButton,
  useTheme,
  Typography,
  ToggleButtonGroup,
} from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { RtlContext} from "../../components/RtlContext";
import React from "react";
const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { isRtl, setIsRtl } = useContext(RtlContext);
  React.useLayoutEffect(() => {
    document.body.setAttribute("dir", isRtl ? "rtl" : "ltr");
  }, [isRtl]);


  const toggleRtl = () => {
    setIsRtl((prev) => !prev);
  };
  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const [alignment, setAlignment] = React.useState(isRtl ? "ar" : "en");
  return (
    <Box
      sx={{
        position: "fixed",

        width: "100%",
        height: "60px", // Fixed height
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: colors.primary[400],
        zIndex: 9, // Ensure it stays above content
        padding: "0 16px",
        boxShadow:
          theme.palette.mode === "dark"
            ? "0px 2px 4px rgba(0, 0, 0, 0.5)"
            : "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
      </Box>

      {/* SEARCH BAR */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton> */}

<IconButton onClick={toggleRtl}>
          <Typography variant="h5" component="div">
            {isRtl ? "English" : "عربى"}
          </Typography>
        </IconButton>
        {/* <IconButton>
          <PersonOutlinedIcon />
        </IconButton> */}
      </Box>
    </Box>
  );
};
export default Topbar;
