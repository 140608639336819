import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import Form from "./Form";
import React, { useContext } from "react";
import { RtlContext } from "../../components/RtlContext";
import en from "../../locales/en.json";
import ar from "../../locales/ar.json";
const LoginPage = () => {
  const { isRtl } = useContext(RtlContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobileScreens = useMediaQuery("(min-width: 500px)");
  return (
    <Box>
      <Box
        width="100%"
        // backgroundColor={colors.primary[400]}
        p="1rem 6%"
        textAlign="center"
      >
        <Typography
          variant="h2"
          fontWeight="bold"
          sx={{ color: colors.grey[100] }}
        >
          {isRtl ? ar.darMisr : en.darMisr}
        </Typography>
      </Box>

      <Box
        width={isNonMobileScreens ? "35%" : "90%"}
        p="2rem "
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={colors.primary[400]}
        textAlign="center"
      >
        <Typography variant="h4" color={colors.grey[100]} sx={{ mb: "2rem" }}>
          {isRtl ? ar.signIn : en.signIn}
        </Typography>
        <Form />
      </Box>
    </Box>
  );
};

export default LoginPage;
