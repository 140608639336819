import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Users from "./scenes/users";
import Form from "./scenes/add";
import Check from "./scenes/CheckActive";
import Notification from "./components/Notification";
import LoginPage from "./scenes/Auth";
//import Invoices from "./scenes/invoices";
//import Bar from "./scenes/bar";
//import Line from "./scenes/line";
//import Pie from "./scenes/pie";
//import FAQ from "./scenes/faq";
//import Geography from "./scenes/geography";
import { Box, Button, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Loading from "./components/Loading";
import { useValue } from "./components/ContextProvider";
import { RtlProvider } from "./components/RtlContext";
import axios from "axios";
import config from "./AppSettings.json";
import Verify from "./scenes/Verify";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJpYXQiOjE3Mjc4NjY0NTZ9.WYVF1XYTR-b3SOJLDvEhPZ_Gj3KE8AXTVa70ynqZ2iA`;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  axios.defaults.baseURL = config.baseUrl;

  const { state } = useValue();
  const isLoggedIn = state.currentUser !== null;
  return (
    <ColorModeContext.Provider value={colorMode}>
      <RtlProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Loading />
          <Notification />
          <div className="app">
            {isLoggedIn && <Sidebar />}
            {isLoggedIn && <Topbar />}
            <main className="content" >
              {!isLoggedIn && <Navigate to="/" />}
              <Routes>
                <Route path="/" element={<LoginPage />} />
                {isLoggedIn && (
                  <>
                    <Route path="/home" element={<Dashboard />} />
                    <Route path="/team" element={<Team />} />
                    {/* <Route path="/users" element={<Users />} /> */}
                    <Route path="/verify" element={<Verify />} />
                    {/* <Route path="/add&edit" element={<Form />} />
                    <Route path="/checkuser" element={<Check />} /> */}
                  </>
                )}
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </RtlProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
