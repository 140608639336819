import React, { useState } from "react";
import {
  Box,
  useTheme,
  TextField,
  Button,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
  
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import ImportUsers from "../../components/ImportUsers";
import axios from "axios";
import { useValue } from "../../components/ContextProvider";
import { firestore } from "../../components/firebaseApp";
import { RtlContext } from "../../components/RtlContext";
import en from "../../locales/en.json";
import ar from "../../locales/ar.json";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  writeBatch,
  getDoc
} from "https://www.gstatic.com/firebasejs/11.0.2/firebase-firestore.js";
import { useContext } from "react";
const Dashboard = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [userIds, setUserIds] = useState("");
  const [carPlate, setCarPlate] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hostData, setHostData] = useState(null);

  const { isRtl } = useContext(RtlContext);
  const lang = isRtl ? ar : en;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let zoneOneBadges = 1256;
  let zoneTwoBadges = 1020;
  let zoneThreeBadges = 754;

  const { dispatch } = useValue();


  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCarPlate("");
  };


  const sanitizeCarPlate = (carPlate) => {
    if (!carPlate || typeof carPlate !== "string") return null;
  
    let sanitized = carPlate.replace(/\//g, ""); // Remove "/"
    sanitized = sanitized.replace(/\s+/g, ""); // Remove spaces
    sanitized = sanitized.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (digit) =>
      "0123456789"["٠١٢٣٤٥٦٧٨٩".indexOf(digit)]
    );
  
    return sanitized || null; // Return null if sanitized string is empty
  };
  
  


  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch({ type: "START_LOADING" });
    let url;
    let payload;
    if (userIds === "") {
      url = "https://gh.darmasr2.com/api/notifications/send-to-all";
      payload = {
        title: title,
        body: message,
      };
    } else {
      url = "https://gh.darmasr2.com/api/notifications/send-to-users";
      payload = {
        notification: {
          title: title,
          body: message,
        },
        userIds: userIds.split(",").map((id) => id.trim()), // Convert to an array
      };
    }
    try {
      if (photoUrl !== "") {
        payload.photoUrl = photoUrl;
      }
      const response = await axios.post(url, payload);

      if (response.status === 200) {
        dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "success",
            title: "Success",
            message: "Notification sent successfully!",
          },
        });
      } else {
        dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            title: "Error",
            message: "Failed to send notification.",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          title: "Error",
          message: "Error sending notification.",
        },
      });
      console.error("API Error:", error);
    } finally {
      dispatch({ type: "END_LOADING" });
    }
  };
  const fetchHostByCarPlate = async () => {
  
    // Sanitize the input car plate
    const sanitizedCarPlate = sanitizeCarPlate(carPlate);
    if (!sanitizedCarPlate) {
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          title: "Error",
          message: "Not Found.",
        },
      });
      return null;
    }
  
    try {
      // Query the CarPlatesIndex collection
      const carPlateDocRef = doc(firestore, "carPlatesIndex", sanitizedCarPlate);
      const carPlateDocSnap = await getDoc(carPlateDocRef);
  
      if (carPlateDocSnap.exists()) {
        const { docId } = carPlateDocSnap.data();
  
        // Fetch the host document from the hosts collection
        const hostDocRef = doc(firestore, "hosts", docId);
        const hostDocSnap = await getDoc(hostDocRef);
  
        if (hostDocSnap.exists()) {
          
        await  setHostData( formatUserData(hostDocSnap.data(), docId));
        handleDialogOpen();
        } else {
          console.error("Host document not found.");
          return null;
        }
      } else {
        console.error("Car plate not found in index.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching host by car plate:", error);
      return null;
    }
  };
  const CarPlateDialog = ({ open, onClose }) => {
   
  
  
    return (
      <Dialog open={open} onClose={onClose}>
      <DialogTitle>Host Information</DialogTitle>
      <DialogContent>
        {hostData ? (
          <>
            <Typography variant="body1"><strong>Name:</strong> {hostData.name}</Typography>
            <Typography variant="body1"><strong>Building:</strong> {hostData.building}</Typography>
            <Typography variant="body1"><strong>Flat:</strong> {hostData.flat}</Typography>
            <Typography variant="body1"><strong>Type:</strong> {hostData.type}</Typography>
            <Typography variant="body1"><strong>Phone:</strong> {hostData.phone}</Typography>
            <Typography variant="body1"><strong>End Date:</strong> {hostData.endDate || "N/A"}</Typography>
            <Typography variant="body1"><strong>Verified:</strong> {hostData.verified}</Typography>

            <Typography variant="h6" style={{ marginTop: "20px" }}>Cards Information:</Typography>
            {hostData.cards.length > 0 ? (
              hostData.cards.map((card, index) => (
                <div key={index} style={{ marginBottom: "10px" }}>
                  <Typography variant="body2"><strong>Card Number:</strong> {card.cardNumber || "N/A"}</Typography>
                  <Typography variant="body2"><strong>Car Plate:</strong> {card.carPlate || "N/A"}</Typography>
                  <Typography variant="body2"><strong>Gate Statuses:</strong></Typography>
                  <Typography variant="body2">Gate 1: {card.gateStatuses.gate1Status}</Typography>
                  <Typography variant="body2">Gate 3: {card.gateStatuses.gate3Status}</Typography>
                  <Typography variant="body2">Gate 4: {card.gateStatuses.gate4Status}</Typography>
                </div>
              ))
            ) : (
              <Typography variant="body2">No cards available</Typography>
            )}
          </>
        ) : (
          <Typography variant="body1">No Data Found</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
  const formatUserData = (userData, id) => {
    // Extract and format endDate if available
    let date;
    if (userData.endDate) {
      date = new Date(parseInt(userData.endDate.seconds) * 1000);
    }

    // Handle multiple cards
    const cards = userData.CardsInfo || [];
    const cardDetails = cards.map((card) => ({
      cardNumber: card.Card || null,
      carPlate: card.CarPlate || null,
      gateStatuses: card.AreActiveOnGates
        ? {
            gate1Status: card.AreActiveOnGates.find((g) => g.GateId === 1)
              ?.IsActive
              ? lang.active
              : lang.inactive,
            gate3Status: card.AreActiveOnGates.find((g) => g.GateId === 3)
              ?.IsActive
              ? lang.active
              : lang.inactive,
            gate4Status: card.AreActiveOnGates.find((g) => g.GateId === 4)
              ?.IsActive
              ? lang.active
              : lang.inactive,
          }
        : {},
    }));

    // If no cards, return a default empty structure
    const formattedCards = cards.length > 0 ? cardDetails : [];

    return {
      id: id,
      building: userData.building,
      flat: userData.flat,
      name: userData.name,
      phone: userData.phone,
      type: userData.type === "owner" ? lang.owner : lang.rent,
      endDate: userData.endDate ? date.toLocaleDateString() : null,
      verified: userData.verified ? lang.verified : lang.notVerified,
      cards: formattedCards, // Updated field to handle multiple cards
    };
    
  };
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>
      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{ color: colors.grey[100] }}
      >
        Send Notification
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          maxWidth: "500px",
          margin: "0 auto",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <TextField
          required
          label="Notification Title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          required
          label="Notification Message"
          variant="outlined"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <TextField
          required
          label="Notification photo URL"
          variant="outlined"
          value={photoUrl}
          onChange={(e) => setPhotoUrl(e.target.value)}
          helperText="Optional: Leave blank if you don't want add photo"
        />
        <TextField
          label="User IDs (comma-separated)"
          variant="outlined"
          value={userIds}
          multiline
          rows={2}
          onChange={(e) => setUserIds(e.target.value)}
          helperText="Optional: Leave blank to send to all users"
        />
        <Button
          type="submit"
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          Send
        </Button>
      </Box>
      <Divider variant="middle" flexItem />

      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{ color: colors.grey[100] }}
      >
        Get User By Car
      </Typography>
      <Box
       
        sx={{
          maxWidth: "500px",
          margin: "0 auto",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <TextField
          required
          label="Car Plate"
          variant="outlined"
          value={carPlate}
          onChange={(e) => setCarPlate(e.target.value)}
        />

        <Button
          onClick={fetchHostByCarPlate}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          Get
        </Button>
      </Box>
      <Divider variant="middle" flexItem />

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(9, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        {/* <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={zoneOneBadges}
            subtitle="Zone 1"
            progress={zoneOneBadges / 11520}
            increase={Math.round((zoneOneBadges / 11520) * 1000) / 10 + "%"}
            icon={
              <PersonIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box> */}
        {/* <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={zoneTwoBadges}
            subtitle="Zone 2"
            progress={zoneTwoBadges / 8700}
            increase={Math.round((zoneTwoBadges / 8700) * 1000) / 10 + "%"}
            icon={
              <PersonIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box> */}
        {/* <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={zoneThreeBadges}
            subtitle="Zone 3"
            progress={zoneThreeBadges / 7000}
            increase={Math.round((zoneThreeBadges / 7000) * 1000) / 10 + "%"}
            icon={
              <PersonIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box> */}

        {/* <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,325,134"
            subtitle="Traffic Received"
            progress="0.80"
            increase="+43%"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box> */}

        {/* ROW 2 */}
        {/* <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Revenue Generated
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                $59,342.32
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} />
          </Box>
        </Box> */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Transactions
            </Typography>
          </Box>
          {mockTransactions.map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${transaction.cost}
              </Box>
            </Box>
          ))}
        </Box> */}

        {/* ROW 3 */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box> */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Sales Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box> */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Geography Based Traffic
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box> */}
      </Box>

      <br />
      <br />
      <ImportUsers />
      <br />
      <Divider variant="middle" flexItem />
      <div>


      <CarPlateDialog
        carPlate={carPlate}
        open={dialogOpen}
        onClose={handleDialogClose}
      />
    </div>
    </Box>
  );
};
export default Dashboard;
