import { tokens } from "../../theme";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
} from "@mui/material";

import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useValue } from "../../components/ContextProvider";
import { firebaseAuth, firestore } from "../../components/firebaseApp";

import React, { useContext } from "react";

import {
  collection,
  doc,
  getDoc,
} from "https://www.gstatic.com/firebasejs/11.0.2/firebase-firestore.js";
import {

  signInWithEmailAndPassword,
} from "https://www.gstatic.com/firebasejs/11.0.2/firebase-auth.js";

import { RtlContext } from "../../components/RtlContext";
import en from "../../locales/en.json";
import ar from "../../locales/ar.json";

const loginSchema = yup.object().shape({
  username: yup.string().required("required"),
  password: yup.string().required("required"),
});

const initialValuesLogin = {
  username: "",
  password: "",
};

const Form = () => {
  const { isRtl } = useContext(RtlContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:300px)");
  const { state, dispatch } = useValue();
  const login = async (values, onSubmitProps) => {
    await signInWithEmailAndPassword(
      firebaseAuth,
      values.username,
      values.password
    )
      .then((userCredential) => {
        const userId = userCredential.user.uid;
        const hostRef = doc(collection(firestore, "admin"), userId);
        getDoc(hostRef).then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            dispatch({
              type: "UPDATE_USER",
              payload: userData,
            });
            navigate("/home");
          } else {
            dispatch({
              type: "UPDATE_ALERT",
              payload: {
                open: true,
                severity: "error",
                message: isRtl?ar.invalid:en.invalid,
              },
            });
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_ALERT",
          payload: { open: true, severity: "error", message: error.message },
        });
      });
    onSubmitProps.resetForm();
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await login(values, onSubmitProps);
  };
  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesLogin}
      validationSchema={loginSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              label= {isRtl?ar.username:en.username}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.username}
              name="username"
              error={Boolean(touched.username) && Boolean(errors.username)}
              helperText={touched.username && errors.username}
              autoComplete="off"
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              fullWidth
              variant="filled"
              label={isRtl?ar.password:en.password}
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              name="password"
              error={Boolean(touched.password) && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              autoComplete="off"
              sx={{ gridColumn: "span 4" }}
            />
          </Box>

          {/* BUTTONS */}
          <Box width="100%" alignItems="center">
            <Button
              fullWidth
              
              type="submit"
              sx={{
                mt: "2rem",
                p: "1rem",
                width: isNonMobile ? "50%" : "90%",
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                "&:hover": { color: colors.blueAccent },
              }}
            >
              {isRtl?ar.signIn:en.signIn}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Form;
