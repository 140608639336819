import {
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { firestore } from "../../components/firebaseApp";
import { RtlContext } from "../../components/RtlContext";
import en from "../../locales/en.json";
import ar from "../../locales/ar.json";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "https://www.gstatic.com/firebasejs/11.0.2/firebase-firestore.js";
import { useValue } from "../../components/ContextProvider";

const Verify = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { isRtl } = useContext(RtlContext);
  const lang = isRtl ? ar : en;
  const { dispatch } = useValue();

  const [hosts, setHosts] = useState([]); // To store fetched host data
  const [currentIndex, setCurrentIndex] = useState(0); // For pagination
  const [loading, setLoading] = useState(false); // Loading state
  const [openDialog, setOpenDialog] = useState(false); // Dialog state for verification confirmation

  // Fetch hosts data when the component mounts
  useEffect(() => {
    const fetchHosts = async () => {
      setLoading(true);
      const hostsCollection = collection(firestore, "hosts");
      const q = query(
        hostsCollection,
        where("verified", "==", false),
        where("idPhoto", "!=", null)
      );

      const querySnapshot = await getDocs(q);
      console.log("Fetched Hosts:", querySnapshot.docs.length);
      const hostsData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
if(data.type==="rent")
{
  console.log("Host:", data);
  console.log("Host Date:", data.endDate);
}
        // Construct SAS URLs for each photo
        const sasURL1 = `https://darmasr.blob.core.windows.net/darmasr2/ids/${doc.id}.jpg?sp=racwdli&st=2024-11-17T17:44:11Z&se=2025-11-27T01:44:11Z&sv=2022-11-02&sr=c&sig=USoAvD5pBVHTAbKJeI9EUmGG5aLtC6J04nF%2FUoJC2Ho%3D`;
        const sasURL2 = `https://darmasr.blob.core.windows.net/darmasr2/contracts/${doc.id}.jpg?sp=racwdli&st=2024-11-17T17:44:11Z&se=2025-11-27T01:44:11Z&sv=2022-11-02&sr=c&sig=USoAvD5pBVHTAbKJeI9EUmGG5aLtC6J04nF%2FUoJC2Ho%3D`;

        return {
          id: doc.id,
          name: data.name, // Add name field
          building: data.building, // Add building field
          flat: data.flat, // Add flat field
          phone: data.phone, // Add phone field
          type: data.type, // Add type field
          endDate: data.endDate, // Add endDate field (if applicable)
          sasURL1,
          sasURL2,
        };
      });
      setHosts(hostsData);
      setLoading(false);
    };

    fetchHosts();
  }, []);

  // Function to handle displaying the current host's images
  const displayHostImages = () => {
    const host = hosts[currentIndex];
  console.log("Host:", host);
  console.log("Host Date:", host.endDate);
    return (
      <Box display="flex" justifyContent="center" alignItems="center" gap={6}>
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="center" gap={6}>
              <img
                src={host.sasURL1}
                alt="id"
                style={{
                  width: "30%",
                  height: "auto",
                  borderRadius: 8,
                }}
              />
              <img
                src={host.sasURL2}
                alt="conract"
                style={{
                  width: "30%",
                  height: "auto",
                  borderRadius: 8,
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  };
  const formatDate = (timestamp) => {
    console.log("Timestamp:", timestamp);
    const date = new Date(timestamp.seconds * 1000);
    const locale = isRtl ? "ar-EG" : "en-US";
    return date.toLocaleDateString(locale, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  // Function to handle the Next button click
  const handleNext = () => {
    if (currentIndex < hosts.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1); // Move to the next host
    } else {
      // Show "No Hosts Found" message if we're at the last host
      setCurrentIndex(hosts.length);
    }
  };

  // Function to accept and update the host verification status
  const handleAccept = async () => {
    const hostDocRef = doc(firestore, "hosts", hosts[currentIndex].id);
    try {
      await updateDoc(hostDocRef, { verified: true });
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "success",
          message: lang.verified,
        },
      });
      setOpenDialog(false);
      handleNext();
    } catch (error) {
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: isRtl ? ar.invalid : en.invalid,
        },
      });
      console.error("Error verifying host: ", error);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        {lang.verifyHosts}
      </Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : hosts.length > 0 ? (
        <Box>
          {displayHostImages()}
          <Box
            sx={{
              marginTop: 2,
              gap: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{`${lang.name}: ${hosts[currentIndex].name}`}</Typography>
            <Typography variant="h6">{`${lang.building}: ${hosts[currentIndex].building}`}</Typography>
            <Typography variant="h6">{`${lang.flat}: ${hosts[currentIndex].flat}`}</Typography>
            <Typography variant="h6">{`${lang.phone}: ${hosts[currentIndex].phone}`}</Typography>
            <Typography variant="h6">{`${lang.type}: ${
              hosts[currentIndex].type === "rent" ? lang.rent : lang.owner
            }`}</Typography>
            {hosts[currentIndex].type === "rent" && (
              <Typography variant="h6">{`${lang.endDate}: ${formatDate(
                hosts[currentIndex].endDate
              )}`}</Typography>
            )}

            <Button
              sx={{
                backgroundColor: colors.redAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                gridColumn: "span 2",
              }}
              onClick={handleNext}
            >
              {lang.reject}
            </Button>
            <Typography variant="h6" sx={{ textAlign: "center", marginTop: 2 }}>
              {`${currentIndex + 1} / ${hosts.length}`}
            </Typography>
            <Button
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                gridColumn: "span 2",
              }}
              onClick={handleAccept}
            >
              {lang.accept}
            </Button>
          </Box>
        </Box>
      ) : (
        <Typography>{lang.noHostsFound}</Typography>
      )}
    </Box>
  );
};

export default Verify;
