import {
  collection,
  doc,
  writeBatch,
} from "https://www.gstatic.com/firebasejs/11.0.2/firebase-firestore.js";
import { read, utils, writeFile } from "xlsx";
import { useState } from "react";
import { MuiFileInput } from "mui-file-input";
import { CloseRounded, Verified } from "@mui/icons-material";
import { Typography, Button, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useValue } from "./ContextProvider";
import { firebaseAuth, firestore } from "./firebaseApp";

const ImportUsers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { state } = useValue();
  const [filedata, setValue] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false); // Add state to track processing status
  const {
    state: { openLogin },
    dispatch,
  } = useValue();
  const [invalidRows, setInvalidRows] = useState([]);
  const handleChange = (newValue) => {
    console.log("File selected:", newValue); // Debugging file change
    setValue(newValue);
  };

  const handleClick = async (e) => {
    // Prevent multiple invocations while already processing
    if (isProcessing) {
      console.log("Import already in progress. Please wait.");
      return;
    }

    if (!filedata) {
      console.log("No file selected");
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "warning",
          title: "Warning",
          message: "Select file first to import",
        },
      });
      return;
    }

    setIsProcessing(true); // Mark as processing
    console.log("Starting file import process");
    dispatch({
      type: "START_LOADING",
    });
    const file = filedata;
    const reader = new FileReader();

    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0]; // Assuming data is in the first sheet
      console.log("Reading sheet:", sheetName);
      const worksheet = workbook.Sheets[sheetName];
      const users = utils.sheet_to_json(worksheet, { header: 1 });

      const batchedWrites = writeBatch(firestore); // Initialize batched writes
      let userCounts = 0;

      for (const user of users.slice(1)) {
        console.log("Processing user:", user); // Debugging user data

        let isValid = true;

        const [
          building,
          name,
          flat,
          phone,
          type,
          endDate,
          badgeCount,
          firstCar,
          firstBadge,
          secondCar,
          secondBadge,
          thirdCar,
          thirdBadge,
          fourthCar,
          fourthBadge,
          fifthCar,
          fifthBadge,
        ] = user;

        if (!building || !name || !flat || !phone || !type) {
          console.log("Missing essential fields for user:", user);
          isValid = false;
        }
        if (type !== "Owner" && type !== "Rent") {
          console.log("Invalid type for user:", user);
          isValid = false;
        }
        if (type === "Rent" && endDate && isNaN(Date.parse(endDate))) {
          console.log("Invalid end date for Rent type:", user);
          isValid = false;
        }
        if (phone) {
          if (phone.toString().length !== 10) {
            console.log("Invalid phone number length:", user);
            isValid = false;
          }
        }
        if (type === "Rent") {
          if (!endDate || isNaN(endDate)) {
            console.log("Invalid or missing end date for Rent type:", user);
            isValid = false;
          }

          const parsedDate = excelDateToJSDate(endDate);

          // Ensure the parsed date is valid and in the future
          if (!(parsedDate instanceof Date) || isNaN(parsedDate)) {
            console.log(
              "End date must be a valid future date for Rent type:",
              user
            );
            isValid = false;
          }
        }

        switch (badgeCount) {
          case "1":
            if (!firstCar || !firstBadge) {
              console.log("Missing car or badge for badgeCount 1:", user);
              isValid = false;
            }
            break;
          case "2":
            if (!firstCar || !firstBadge || !secondCar || !secondBadge) {
              console.log("Missing car or badge for badgeCount 2:", user);
              isValid = false;
            }
            break;
          case "3":
            if (
              !firstCar ||
              !firstBadge ||
              !secondCar ||
              !secondBadge ||
              !thirdCar ||
              !thirdBadge
            ) {
              console.log("Missing car or badge for badgeCount 3:", user);
              isValid = false;
            }
            break;
          case "4":
            if (
              !firstCar ||
              !firstBadge ||
              !secondCar ||
              !secondBadge ||
              !thirdCar ||
              !thirdBadge ||
              !fourthCar ||
              !fourthBadge
            ) {
              console.log("Missing car or badge for badgeCount 4:", user);
              isValid = false;
            }
            break;
          case "5":
            if (
              !firstCar ||
              !firstBadge ||
              !secondCar ||
              !secondBadge ||
              !thirdCar ||
              !thirdBadge ||
              !fourthCar ||
              !fourthBadge ||
              !fifthCar ||
              !fifthBadge
            ) {
              console.log("Missing car or badge for badgeCount 5:", user);
              isValid = false;
            }
            break;
          default:
            break;
        }

        if (!isValid) {
          console.log("User is invalid, skipping:", user);
          invalidRows.push(user);
          continue;
        }

        let userId = `${building}-${flat}${type.charAt(0).toLocaleLowerCase()}`;
        if (!userId) {
          console.log("Invalid user ID for user:", user);
          invalidRows.push(user);
          continue;
        }

        const hostRef = doc(collection(firestore, "hosts"), userId);
        console.log("Creating user reference for:", userId);

        const property = {
          name: name.toString(),
          building: building,
          flat: flat,
          phone: phone.toString(),
          type: type.toLowerCase(),
          verified: true,
          cardsInfo: [],
        };

        if (type.toLowerCase() === "rent") {
          const date = excelDateToJSDate(endDate);
          property.endDate = date; // Set endDate if Rent
        }

        for (let i = 0; i < badgeCount; i++) {
          const carIndex = 7 + i * 2;
          const badgeIndex = 8 + i * 2;
          const carPlate = user[carIndex]?.toString().trim() || "";
          const card = user[badgeIndex]?.toString().trim() || "";

          if (!carPlate || !card) {
            console.log(`Missing car or badge for badgeCount ${i + 1}:`, user);
            isValid = false;
            break; // Exit the loop for invalid data
          }
          const cardInfo = {
            card: user[badgeIndex].toString(),
            carPlate: user[carIndex].toString(),
            areActiveOnGates: [
              { gateId: 1, isActive: true },
              { gateId: 3, isActive: true },
              { gateId: 4, isActive: true },
            ],
          };
          if (!isValid) {
            console.log("User is invalid, skipping:", user);
            invalidRows.push(user);
            continue;
          }
          property.cardsInfo.push(cardInfo); // Add to cardsInfo
        }
        console.log("Creating user reference for data:", property);
        // Use batched write instead of setDoc
        batchedWrites.set(hostRef, property);
        userCounts++;
      }

      console.log("Committing batched writes");
      // Commit batched writes
      await batchedWrites.commit();

      setIsProcessing(false); // Mark as finished processing
      dispatch({
        type: "END_LOADING",
      });
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "Success",
          title: "Success",
          message: `${userCounts} Users imported successfully!`,
        },
      });
      if (invalidRows.length > 0) {
        console.log("Exporting invalid rows:", invalidRows);
        exportInvalidRows(invalidRows);
      }
    };

    reader.readAsArrayBuffer(file);
  };
  const exportInvalidRows = (invalidRows) => {
    const ws = utils.aoa_to_sheet([
      [
        "Building",
        "Name",
        "Flat",
        "Phone",
        "Type",
        "EndDate",
        "BadgeCount",
        "Car1",
        "Badge1",
        "Car2",
        "Badge2",
        "Car3",
        "Badge3",
        "Car4",
        "Badge4",
        "Car5",
        "Badge5",
      ],
      ...invalidRows,
    ]); // Add headers to invalid rows
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Invalid Users");
    writeFile(wb, "Invalid_Users.xlsx"); // Trigger download of the invalid users file
    setInvalidRows([]);
  };
  function excelDateToJSDate(serial) {
    const excelEpoch = new Date(1899, 11, 30); // Excel epoch start
    const days = Math.floor(serial);
    const seconds = Math.floor((serial % 1) * 86400);
    const ms = (seconds % 1) * 1000;

    // Add days and time to the Excel epoch date
    return new Date(
      excelEpoch.getTime() + days * 86400 * 1000 + seconds * 1000 + ms
    );
  }

  return (
    <Box>
      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{ color: colors.grey[100] }}
      >
        Import Users
      </Typography>

      <Box
        gridTemplateColumns="repeat(6, 2fr)"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gridAutoRows="300px"
        gap="20px"
      >
        {/* HEADER */}

        <Box gridColumn="span 2">
          <MuiFileInput
            id="csv-file"
            variant="filled"
            inputProps={{ accept: ".xlsx" }}
            placeholder="Insert a file"
            value={filedata}
            onChange={handleChange}
            clearIconButtonProps={{
              title: "Remove",
              children: <CloseRounded fontSize="medium" />,
            }}
          />
        </Box>
        <br />
        <Box gridColumn="span 2">
          <Button
            id="import-btn"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={handleClick}
            disabled={isProcessing} // Disable button while processing
          >
            Import
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ImportUsers;
